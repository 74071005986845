<template>
  <!-- 面包屑 -->
  <a-breadcrumb>
    <a-breadcrumb-item>推广管理</a-breadcrumb-item>
    <a-breadcrumb-item>未回传数据</a-breadcrumb-item>
  </a-breadcrumb>
  <!-- 功能条 -->
  <div class="tool-view">
    <!-- 日期： -->
    <span class="tool-title">日期：</span>
    <a-range-picker
      v-model:value="dateRange"
      :format="dateFormat"
    />
    <!-- 推广ID： -->
    <span class="tool-title">推广ID：</span>
    <a-input v-model:value="promotionID" style="width: 160px;" placeholder="请输入" />
    <!-- 计划ID： -->
    <span class="tool-title">计划ID：</span>
    <a-input v-model:value="planID" style="width: 160px;" placeholder="请输入" />
    <!-- 是否首充： -->
    <span class="tool-title">是否首充：</span>
    <a-select v-model:value="first" style="width: 120px;" placeholder="请选择">
      <a-select-option :value="0">全部</a-select-option>
      <a-select-option v-for="item in FirstChargeTypes" :key="item.value" :value="item.value">{{ item.text }}</a-select-option>
    </a-select>
    <!-- 推广渠道： -->
    <span class="tool-title">推广渠道：</span>
    <a-select v-model:value="channel" style="width: 120px;" placeholder="请选择">
      <a-select-option :value="0">全部</a-select-option>
      <a-select-option v-for="item in ChannelTypes" :key="item.value" :value="item.value">{{ item.text }}</a-select-option>
    </a-select>
    <!-- 操作 -->
    <a-button type="primary" style="margin-left: 16px;" @click="handleSearch">查询</a-button>
    <a-button style="margin-left: 16px;" @click="handleReset">重置</a-button>
    <div style="flex: 1;"></div>
  </div>
   <!-- 多选组件 -->
   <TableCheckbox
    sourceKey="id"
    :dataSource="dataSource"
    :selectedRowKeys="selectedRowKeys"
    :selectedRows="selectedRows"
    :pagination="pagination"
    :isLoading="isLoading"
    @submit="touchBatchReturn"
  >
    <!-- 按钮文案 -->
    <template #checkbox-title>批量回传</template>
    <!-- 列表 -->
    <a-table
      class="table-view"
      :data-source="dataSource"
      :columns="columns"
      :row-key="record => record.id"
      :pagination="pagination"
      :loading="isLoading"
      :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
      @change="handleTableChange"
    >
      <!-- 自定义行内容 -->
      <template #bodyCell="{ column, record }">
        <!-- 推广渠道 -->
        <template v-if="column.key === 'channel'">
          {{ (ChannelTypes.find(item => item.value === record[column.key]) || {}).text || '-' }}
        </template>
        <!-- 金额 -->
        <template v-if="column.key === 'amount'">
          {{ $pub.KEEP_NUMBER_DECIMAL((record[column.key] || 0) / 100, 2, false, true) }}
        </template>
        <!-- 是否首充 -->
        <template v-if="column.key === 'is_first_pay'">
          {{ (FirstChargeTypes.find(item => item.value === record[column.key]) || {}).text || '-' }}
        </template>
        <!-- 操作 -->
        <template v-if="column.key === 'operation'">
          <a class="operation-item" @click="touchReturn(record)">回传</a>
        </template>
      </template>
    </a-table>
  </TableCheckbox>
</template>

<script setup>
import { onBeforeMount, ref, reactive } from 'vue'
import Pub from '@/utils/public'
import { message, Modal } from 'ant-design-vue'
import { FirstChargeTypes, ChannelTypes } from '@/utils/constantList'
import dayjs from 'dayjs'
import { callbackList, batchCallback } from '@/api/operate'
import TableCheckbox from '@/components/TableCheckbox'

// 加载
let isLoading = ref(false)
// 日期范围
let dateRange = ref([dayjs().subtract(30, 'day'), dayjs()])
// 日期format
const dateFormat = 'YYYY-MM-DD'
// 推广ID
let promotionID = ref(undefined)
// 计划ID
let planID = ref(undefined)
// 是否首充
let first = ref(undefined)
// 推广渠道
let channel = ref(undefined)
// 数据源
let dataSource = ref([])
// 选中
let selectedRowKeys = ref([])
let selectedRows = ref([])
// 分页信息
let pagination = reactive({
  total: 0,
  current: 1,
  pageSize: 10,
  showSizeChanger: true
})
// 列数据源（付费转化率、权重本期不做）
let columns = ref([
  {
    title: '支付时间',
    dataIndex: 'trade_at',
    key: 'trade_at'
  },
  {
    title: 'UID',
    dataIndex: 'user_id',
    key: 'user_id'
  },
  {
    title: '推广ID',
    dataIndex: 'adv_id',
    key: 'adv_id'
  },
  {
    title: '绑定时间',
    dataIndex: 'user_click_time',
    key: 'user_click_time'
  },
  {
    title: '金额(元)',
    dataIndex: 'amount',
    key: 'amount'
  },
  {
    title: '是否首充',
    dataIndex: 'is_first_pay',
    key: 'is_first_pay'
  },
  {
    title: '推广渠道',
    dataIndex: 'channel',
    key: 'channel'
  },
  {
    title: '计划ID',
    dataIndex: 'adid',
    key: 'adid'
  },
  {
    title: '操作',
    dataIndex: 'operation',
    key: 'operation'
  }
])
// 钩子函数
onBeforeMount (() => {
  // 获取注册用户列表
  getList()
})

// 获取注册用户列表
function getList () {
  isLoading.value = true
  const params = {
    status: -1,
    start_time: dateRange.value ? `${dateRange.value[0].format(dateFormat)}` : undefined,
    end_time: dateRange.value ? `${dateRange.value[1].format(dateFormat)}` : undefined,
    adv_id: promotionID.value,
    adid: planID.value,
    is_first_pay: first.value,
    channel: channel.value,
    page: pagination.current,
    page_size: pagination.pageSize
  }
  callbackList(params).then(res => {
    isLoading.value = false
    const { code, data, msg } = res
    if (code === 0) {
      dataSource.value = data.data
      pagination.total = data.total
    } else {
      message.error(res.message || msg)
    }
  }).catch(() => {
    isLoading.value = false
  })
}

// 分页处理
function handleTableChange (p, filters, sorter) {
  pagination.current = p.current
  pagination.pageSize = p.pageSize
  getList()
}

// 查询
function handleSearch () {
  pagination.current = 1
  // 获取列表
  getList()
}

// 重置
function handleReset () {
  // 筛选项重置
  dateRange.value = [dayjs().subtract(30, 'day'), dayjs()]
  promotionID.value = undefined
  planID.value = undefined
  first.value = undefined
  channel.value = undefined
  // 分页重置
  pagination.current = 1
  getList()
}


// 重新回传
function touchReturn (record) {
  // 回传
  setBatchCallback([record.id])
}

// 多选处理
function onSelectChange (rowKeys, rows) {
  selectedRowKeys.value = rowKeys
  selectedRows.value = rows
}

// 批量回传
function touchBatchReturn () {
  if (selectedRowKeys.value.length) {
    // 回传
    setBatchCallback(selectedRowKeys.value)
  } else {
    message.error('未选中回传数据')
  }
}

// 批量回传
function setBatchCallback (ids) {
  isLoading.value = true
  const params = {
    ids: ids
  }
  batchCallback(params).then(res => {
    isLoading.value = false
    const { code, data, msg } = res
    if (code === 0) {
      message.success('回传成功')
      getList()
    } else {
      Modal.error({
        title: '回传失败',
        content: (res.message || msg), // '仅可回传24小时内的充值'
      })
    }
  }).catch(() => {
    isLoading.value = false
    message.error('回传失败')
  })
}
</script>

<style scoped>
.tool-view {
  display: flex;
  align-items: center;
  margin-top: 30px;
}
.table-view {
  margin-top: 30px;
}
.tool-title:not(:first-child) {
  margin-left: 16px;
}

</style>